<template>
  <div>
    <Menu />
    <h1>TireCheck</h1>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu'
import Footer from '@/components/Footer'

export default {
  name: 'TireCheck',
  components: {
    Menu,
    Footer
  }
}
</script>